import { gql } from "@apollo/client"
import { Button, ButtonGroup, Divider, HStack, ModalFooter, Stack, useBoolean } from "@chakra-ui/react"
import { useRouter } from "next/router"

import {
  GetEntityAttachmentsDocument,
  useCreateAttachmentMutation,
  useCreateManyAttachmentsMutation,
} from "lib/graphql"
import { useForm } from "lib/hooks/useForm"
import { useS3BulkUpload, useS3Upload } from "lib/hooks/useS3"
import { useToast } from "lib/hooks/useToast"
import { useBetterTranslation } from "lib/hooks/useTranslation"
import { UPLOAD_PATHS } from "lib/uploadPaths"
import { UploadSchema } from "lib/yup"
import Yup from "lib/yup"

import { FileInput } from "./FileInput"
import { Form } from "./Form"
import { Input } from "./Input"
import { MultiFileInput } from "./MultiFileInput"
import dayjs from "dayjs"
import { useMe } from "lib/hooks/useMe"

const _ = gql`
  mutation CreateAttachment($data: AttachmentCreateInput!) {
    createAttachment(data: $data) {
      id
      name
      file
      entityId
      year
    }
  }
`

interface Props {
  onClose: () => void
  files?: File[]
}

export function CreateAttachmentForm(props: Props) {
  const bt = useBetterTranslation()
  const [isMulti, { on, off }] = useBoolean(props.files && props.files.length > 1)

  return (
    <Stack spacing={4}>
      <HStack>
        <Button
          colorScheme={isMulti ? "gray" : "pink"}
          variant={isMulti ? "outline" : "solid"}
          w="100%"
          onClick={off}
        >
          {bt({ en: "Single", nl: "Enkel" })}
        </Button>
        <Button
          colorScheme={isMulti ? "pink" : "gray"}
          variant={isMulti ? "solid" : "outline"}
          w="100%"
          onClick={on}
        >
          {bt({ en: "Multi", nl: "Meerdere" })}
        </Button>
      </HStack>
      <Divider />
      {isMulti ? <CreateMultiAttachmentForm {...props} /> : <CreateSingleAttachmentForm {...props} />}
    </Stack>
  )
}

export const AttachmentFormSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  file: UploadSchema,
})

export function CreateSingleAttachmentForm({ onClose, files }: Props) {
  const defaultValues = {
    file: files?.[0] || "",
    name: files?.[0]?.name.split(".")[0] || "",
  }

  const bt = useBetterTranslation()

  const router = useRouter()
  const toast = useToast()
  const [upload] = useS3Upload()
  const form = useForm({ defaultValues, schema: AttachmentFormSchema })

  const id = router.query.id as string | undefined
  const year = router.query.year as string | undefined
  const [createAttachment, { loading }] = useCreateAttachmentMutation()

  const handleUpdate = async (data: Yup.InferType<typeof AttachmentFormSchema>) => {
    if (!id || !year) return
    let file = data.file && typeof data.file !== "string" ? data.file : undefined
    if (!file)
      return toast({
        status: "error",
        description: bt({ en: "Please upload a file", nl: "Upload een bestand" }),
      })
    if (file && typeof file !== "string") {
      try {
        file = (await upload(file, { path: UPLOAD_PATHS.entityAttachment(id) })).fileKey
      } catch {
        return toast({
          status: "error",
          description: bt({
            en: "Error uploading, please try again",
            nl: "Fout bij het uploaden, probeer het opnieuw.",
          }),
        })
      }
    }
    return form.handler(
      () =>
        createAttachment({
          variables: { data: { year: parseInt(year), file, name: data.name, entity: { connect: { id } } } },
          refetchQueries: [GetEntityAttachmentsDocument],
        }),
      {
        onSuccess: (_, toast) => {
          onClose()
          toast({
            description: bt({ en: "Uploaded other attachment", nl: "Bijlages & documenten geuploaden" }),
          })
        },
      },
    )
  }

  return (
    <Form {...form} onSubmit={handleUpdate}>
      <Stack>
        <Input name="name" variant="outline" label={bt({ en: "Name", nl: "Naam" })} colorScheme="pink" />
        <FileInput
          name="file"
          label={bt({ en: "Upload other attachments", nl: "Bijlages & documenten uploaden" })}
          acceptAllFiletypes
        />
        <ModalFooter p={0}>
          <ButtonGroup>
            {form.formState.isDirty && (
              <Button variant="ghost" size="sm" onClick={() => form.reset(defaultValues)}>
                {bt({ en: "Cancel", nl: "Annuleer" })}
              </Button>
            )}
            <Button
              type="submit"
              isDisabled={form.formState.isSubmitting || (!form.formState.isDirty && !files) || loading}
              isLoading={form.formState.isSubmitting || loading}
              colorScheme="pink"
              size="sm"
            >
              {bt({ en: "Create", nl: "Aanmaken" })}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Stack>
    </Form>
  )
}

const MultiAttachmentFormSchema = Yup.object().shape({
  files: Yup.array(Yup.mixed<typeof UploadSchema | string>().required("Required")).required("Required"),
})

function CreateMultiAttachmentForm({ onClose, files }: Props) {
  const { me } = useMe()
  const toast = useToast()
  const router = useRouter()
  const bt = useBetterTranslation()
  const year = router.query.year as string | undefined
  const entityId = router.query.id as string | undefined

  const defaultValues = { files: files || [] }

  const [upload] = useS3BulkUpload({
    path: UPLOAD_PATHS.entityInvoice(entityId || ""),
  })
  const form = useForm({ defaultValues, schema: MultiAttachmentFormSchema })

  const [createAttachments, { loading }] = useCreateManyAttachmentsMutation()

  const handleUpdate = async (data: typeof defaultValues) => {
    if (!entityId || !year || !me) return

    if (data.files.length === 0)
      return toast({ description: "Please upload at least one file", status: "error" })

    const uploadedFiles = await upload(data.files)

    if (!uploadedFiles) return toast({ description: "Error uploading files", status: "error" })

    const date = dayjs().year(parseInt(year)).startOf("year").add(1, "day").toISOString()

    return form.handler(
      () =>
        createAttachments({
          variables: {
            data: uploadedFiles.map((file) => ({
              name: file.fileName,
              date,
              file: file.fileKey,
              entityId,
              uploaderId: me.id,
            })),
          },
          refetchQueries: [GetEntityAttachmentsDocument],
        }),
      {
        onSuccess: (_, toast) => {
          onClose()
          toast({
            description: bt({
              en: `${uploadedFiles.length} Attachments uploaded`,
              nl: `${uploadedFiles.length} Bijlagen geupload`,
            }),
          })
        },
      },
    )
  }

  return (
    <Form {...form} onSubmit={handleUpdate}>
      <Stack>
        <MultiFileInput
          name="files"
          label={bt({ en: "Upload attachments", nl: "Bijlagen uploaden" })}
          acceptAllFiletypes
        />
        <ModalFooter p={0}>
          <ButtonGroup>
            {form.formState.isDirty && (
              <Button variant="ghost" size="sm" onClick={() => form.reset(defaultValues)}>
                {bt({ en: "Cancel", nl: "Annuleer" })}
              </Button>
            )}
            <Button
              type="submit"
              isDisabled={form.formState.isSubmitting || (!form.formState.isDirty && !files) || loading}
              isLoading={form.formState.isSubmitting || loading}
              colorScheme="pink"
              size="sm"
            >
              {bt({ en: "Create", nl: "Aanmaken" })}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Stack>
    </Form>
  )
}
