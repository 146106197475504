import * as React from "react"
import { FiAlertTriangle } from "react-icons/fi"

interface Props {
  children: React.ReactNode
}
export function NoData(props: Props) {
  return (
    <div className="flex items-center space-x-4 border border-gray-100 px-4 py-3 dark:border-gray-700">
      <FiAlertTriangle className="opacity-50" />
      <div className="opacity-60">{props.children}</div>
    </div>
  )
}
