import dayjs from "dayjs"
import { useBetterTranslation } from "lib/hooks/useTranslation"
import Link from "next/link"

export function Footer() {
  const bt = useBetterTranslation()
  return (
    <footer className="px-4 py-2 fixed bottom-0 w-full flex gap-2 flex-wrap bg-neutral-50 dark:bg-neutral-900 border-t border-gray-200 dark:border-gray-700 text-gray-600">
      <p className="text-xs">{`Copyright © ${dayjs().year()} GREY MEN B.V.`}</p>
      <div className="flex space-x-2">
        <Link href="/privacy" className="text-xs underline">
          {bt({ en: "privacy policy", nl: "privacybeleid" })}
        </Link>
        {/* <Link href="/terms" className="text-xs underline">
          {bt({ en: "terms & conditions", nl: "algemene voorwaarden" })}
        </Link> */}
      </div>
    </footer>
  )
}
