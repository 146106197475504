import { gql } from "@apollo/client"
import { Button, ButtonGroup, Divider, HStack, ModalFooter, Stack, useBoolean } from "@chakra-ui/react"
import { useRouter } from "next/router"

import {
  GetEntityBankStatementsDocument,
  useCreateBankStatementMutation,
  useCreateManyBankStatementsMutation,
} from "lib/graphql"
import { BANK_STATEMENT_FILETYPES } from "lib/helpers/isValidFile"
import { useForm } from "lib/hooks/useForm"
import { useS3BulkUpload, useS3Upload } from "lib/hooks/useS3"
import { useToast } from "lib/hooks/useToast"
import { useBetterTranslation } from "lib/hooks/useTranslation"
import { UPLOAD_PATHS } from "lib/uploadPaths"
import { UploadSchema } from "lib/yup"
import Yup from "lib/yup"

import { FileInput } from "./FileInput"
import { Form } from "./Form"
import { Input } from "./Input"
import { MultiFileInput } from "./MultiFileInput"
import dayjs from "dayjs"
import { useMe } from "lib/hooks/useMe"
import { DateInput } from "./DateInput"

const _ = gql`
  mutation CreateBankStatement($data: BankStatementCreateInput!) {
    createBankStatement(data: $data) {
      id
      name
      file
      entityId
      year
    }
  }
`

interface Props {
  onClose: () => void
  files?: File[]
}

export function CreateBankStatementForm(props: Props) {
  const bt = useBetterTranslation()
  const [isMulti, { on, off }] = useBoolean(props.files && props.files.length > 1)

  return (
    <Stack spacing={4}>
      <HStack>
        <Button
          colorScheme={isMulti ? "gray" : "pink"}
          variant={isMulti ? "outline" : "solid"}
          w="100%"
          onClick={off}
        >
          {bt({ en: "Single", nl: "Enkel" })}
        </Button>
        <Button
          colorScheme={isMulti ? "pink" : "gray"}
          variant={isMulti ? "solid" : "outline"}
          w="100%"
          onClick={on}
        >
          {bt({ en: "Multi", nl: "Meerdere" })}
        </Button>
      </HStack>
      <Divider />
      {isMulti ? <CreateMultiBankStatementForm {...props} /> : <CreateSingleBankStatementForm {...props} />}
    </Stack>
  )
}

export const BankStatementFormSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  date: Yup.string().required("Required"),
  file: UploadSchema,
})

export function CreateSingleBankStatementForm({ onClose, files }: Props) {
  const toast = useToast()
  const router = useRouter()
  const [upload] = useS3Upload()
  const bt = useBetterTranslation()

  const id = router.query.id as string | undefined
  const [createBankStatement, { loading }] = useCreateBankStatementMutation()

  const defaultValues = {
    file: files?.[0] || "",
    name: files?.[0]?.name.split(".")[0] || "",
    date: "",
  }
  const form = useForm({ defaultValues, schema: BankStatementFormSchema })

  const handleUpdate = async (data: Yup.InferType<typeof BankStatementFormSchema>) => {
    if (!id) return
    let file = data.file && typeof data.file !== "string" ? data.file : undefined
    if (!file)
      return toast({
        status: "error",
        description: bt({ en: "Please upload a file", nl: "Upload een bestand" }),
      })
    if (file && typeof file !== "string") {
      try {
        file = (await upload(file, { path: UPLOAD_PATHS.entityBankStatement(id) })).fileKey
      } catch {
        return toast({
          status: "error",
          description: bt({
            en: "Error uploading, please try again",
            nl: "Fout bij het uploaden, probeer het opnieuw.",
          }),
        })
      }
    }
    return form.handler(
      () =>
        createBankStatement({
          variables: {
            data: {
              ...data,
              file,
              entity: { connect: { id } },
            },
          },
          refetchQueries: [GetEntityBankStatementsDocument],
        }),
      {
        onSuccess: (_, toast) => {
          onClose()
          toast({ description: bt({ en: "Uploaded bank statement", nl: "Bankafschrift geupload" }) })
        },
      },
    )
  }

  return (
    <Form {...form} onSubmit={handleUpdate}>
      <Stack>
        <Input name="name" variant="outline" label={bt({ en: "Name", nl: "Naam" })} colorScheme="pink" />
        <DateInput label={bt({ en: "Date of bank statement", nl: "Datum van bankafschrift" })} name="date" />
        <FileInput
          name="file"
          label={bt({ en: "Upload bank statements", nl: "Bankafschriften uploaden" })}
          subLabel={bt({
            en: `supported filetypes: ${BANK_STATEMENT_FILETYPES.join(", ")}`,
            nl: `ondersteunde bestandstypen: ${BANK_STATEMENT_FILETYPES.join(", ")}`,
          })}
          customFiletypes={BANK_STATEMENT_FILETYPES}
        />
        <ModalFooter p={0}>
          <ButtonGroup>
            {form.formState.isDirty && (
              <Button variant="ghost" size="sm" onClick={() => form.reset(defaultValues)}>
                {bt({ en: "Cancel", nl: "Annuleer" })}
              </Button>
            )}
            <Button
              type="submit"
              isDisabled={form.formState.isSubmitting || (!form.formState.isDirty && !files) || loading}
              isLoading={form.formState.isSubmitting || loading}
              colorScheme="pink"
              size="sm"
            >
              {bt({ en: "Create", nl: "Aanmaken" })}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Stack>
    </Form>
  )
}

const MultiBankStatementFormSchema = Yup.object().shape({
  files: Yup.array(Yup.mixed<typeof UploadSchema | string>().required("Required")).required("Required"),
})

function CreateMultiBankStatementForm({ onClose, files }: Props) {
  const { me } = useMe()
  const toast = useToast()
  const router = useRouter()
  const bt = useBetterTranslation()
  const year = router.query.year as string | undefined
  const entityId = router.query.id as string | undefined

  const defaultValues = {
    files: files || [],
  }

  const [upload] = useS3BulkUpload({
    path: UPLOAD_PATHS.entityBankStatement(entityId || ""),
  })
  const form = useForm({ defaultValues, schema: MultiBankStatementFormSchema })

  const [createBankStatements, { loading }] = useCreateManyBankStatementsMutation()

  const handleUpdate = async (data: typeof defaultValues) => {
    if (!entityId || !year || !me) return

    if (data.files.length === 0)
      return toast({ description: "Please upload at least one file", status: "error" })

    const uploadedFiles = await upload(data.files)

    if (!uploadedFiles) return toast({ description: "Error uploading files", status: "error" })

    const date = dayjs().year(parseInt(year)).startOf("year").add(1, "day").toISOString()

    return form.handler(
      () =>
        createBankStatements({
          variables: {
            data: uploadedFiles.map((file) => ({
              name: file.fileName,
              date,
              file: file.fileKey,
              entityId,
              uploaderId: me.id,
            })),
          },
          refetchQueries: [GetEntityBankStatementsDocument],
        }),
      {
        onSuccess: (_, toast) => {
          onClose()
          toast({
            description: bt({
              en: `${uploadedFiles.length} Bank statements uploaded`,
              nl: `${uploadedFiles.length} Bankafschriften geupload`,
            }),
          })
        },
      },
    )
  }

  return (
    <Form {...form} onSubmit={handleUpdate}>
      <Stack>
        <MultiFileInput
          name="files"
          label={bt({ en: "Upload bank statements", nl: "Bankafschriften uploaden" })}
          subLabel={bt({
            en: `supported filetypes: ${BANK_STATEMENT_FILETYPES.join(", ")}`,
            nl: `ondersteunde bestandstypen: ${BANK_STATEMENT_FILETYPES.join(", ")}`,
          })}
          customFiletypes={BANK_STATEMENT_FILETYPES}
        />
        <ModalFooter p={0}>
          <ButtonGroup>
            {form.formState.isDirty && (
              <Button variant="ghost" size="sm" onClick={() => form.reset(defaultValues)}>
                {bt({ en: "Cancel", nl: "Annuleer" })}
              </Button>
            )}
            <Button
              type="submit"
              isDisabled={form.formState.isSubmitting || (!form.formState.isDirty && !files) || loading}
              isLoading={form.formState.isSubmitting || loading}
              colorScheme="pink"
              size="sm"
            >
              {bt({ en: "Create", nl: "Aanmaken" })}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Stack>
    </Form>
  )
}
